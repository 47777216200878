<template>
  <view-item title="收付款统计">
    <template #operation>
      <en-button type="primary" @click="operation.total.click">查看总计</en-button>
      <en-button
        v-if="(tabs.active === 'customer' && customer.mode === 'daily') || (tabs.active === 'payment' && payment.mode === 'daily')"
        type="primary"
        @click="operation.export.click"
      >
        导出
      </en-button>
    </template>

    <en-card body-class="h-full" class="h-full">
      <flex-box>
        <template #default="{ height }">
          <en-tabs v-model="tabs.active">
            <en-tab-pane label="收款统计" name="customer" :style="{ height: height - 55 + 'px', overflow: 'auto' }">
              <div :style="{ height: `${height - 50}px` }">
                <form-query :method="customer.table[customer.mode]" :model="customer.form.data">
                  <en-form-item label="统计类型">
                    <en-checkbox v-model="customer.mode" true-label="daily" false-label="daily">按日统计</en-checkbox>
                    <en-checkbox v-model="customer.mode" true-label="monthly" false-label="monthly">按月统计</en-checkbox>
                    <en-checkbox v-model="customer.mode" true-label="detail" false-label="detail">收款明细报表</en-checkbox>
                    <en-checkbox v-model="customer.mode" true-label="type" false-label="type">收款分类报表</en-checkbox>
                  </en-form-item>
                  <en-form-item label="收款日期" v-if="customer.mode === 'daily'">
                    <en-date-picker
                      v-model:start="customer.form.data.receiptStartDate"
                      v-model:end="customer.form.data.receiptEndDate"
                      type="daterange"
                      value-format="YYYY-MM-DD"
                    ></en-date-picker>
                  </en-form-item>
                  <en-form-item label="收款日期" v-if="customer.mode === 'monthly'">
                    <en-date-picker
                      v-model:start="customer.form.data.receiptStartDate"
                      v-model:end="customer.form.data.receiptEndDate"
                      type="monthrange"
                      value-format="YYYY-MM-DD"
                    ></en-date-picker>
                  </en-form-item>
                  <en-form-item label="收款日期" v-if="customer.mode === 'detail'">
                    <en-date-picker
                      v-model:start="customer.form.data.receipt.businessStartDate"
                      v-model:end="customer.form.data.receipt.businessEndDate"
                      type="daterange"
                      value-format="YYYY-MM-DD"
                    ></en-date-picker>
                  </en-form-item>
                  <en-form-item label="收款日期" v-if="customer.mode === 'type'">
                    <en-date-picker
                      v-model:start="customer.form.data.receipt.businessStartDate"
                      v-model:end="customer.form.data.receipt.businessEndDate"
                      type="daterange"
                      value-format="YYYY-MM-DD"
                    ></en-date-picker>
                  </en-form-item>
                </form-query>

                <flex-box>
                  <template #default="{ height }">
                    <table-dynamic
                      v-if="customer.mode === 'daily'"
                      code="RTDYSTAFLD"
                      :height="height"
                      :data="customer.table.data"
                      :loading="customer.table.loading"
                      :paging="customer.table.paging"
                      :summary="customer.table.summary.data"
                      :method="customer.table.daily"
                      pagination
                      show-summary
                    >
                      <template #INDEX="{ $index }">
                        {{ $index + 1 }}
                      </template>
                    </table-dynamic>

                    <table-dynamic
                      v-if="customer.mode === 'monthly'"
                      code="RTMYSTAFLD"
                      :height="height"
                      :data="customer.table.data"
                      :loading="customer.table.loading"
                      :paging="customer.table.paging"
                      :summary="customer.table.summary.data"
                      :method="customer.table.monthly"
                      pagination
                      show-summary
                    >
                      <template #INDEX="{ $index }">
                        {{ $index + 1 }}
                      </template>
                    </table-dynamic>

                    <table-dynamic
                      v-if="customer.mode === 'detail'"
                      code="RPTDLSTAFLD"
                      :height="height"
                      :data="customer.table.data"
                      :loading="customer.table.loading"
                      :paging="customer.table.paging"
                      :summary="customer.table.summary.data"
                      :method="customer.table.detail"
                      pagination
                      show-summary
                      :config="customer.config"
                    >
                      <template #INDEX="{ $index }">
                        {{ $index + 1 }}
                      </template>
                    </table-dynamic>

                    <table-dynamic
                      v-if="customer.mode === 'type'"
                      code="RPTPYMDFLD"
                      :height="height"
                      :data="customer.table.data"
                      :loading="customer.table.loading"
                      :paging="customer.table.paging"
                      :summary="customer.table.summary.data"
                      :method="customer.table.type"
                      pagination
                      show-summary
                      :config="customer.config"
                    >
                      <template #INDEX="{ $index }">
                        {{ $index + 1 }}
                      </template>
                    </table-dynamic>
                  </template>
                </flex-box>
              </div>
            </en-tab-pane>

            <en-tab-pane label="付款统计" name="payment" :style="{ height: height - 55 + 'px', overflow: 'auto' }">
              <form-query :method="payment.table[payment.mode]" :model="payment.form.data">
                <en-form-item label="统计类型">
                  <en-checkbox v-model="payment.mode" true-label="daily" false-label="daily">按日统计</en-checkbox>
                  <en-checkbox v-model="payment.mode" true-label="monthly" false-label="monthly">按月统计</en-checkbox>
                  <en-checkbox v-model="payment.mode" true-label="detail" false-label="detail">付款明细报表</en-checkbox>
                  <en-checkbox v-model="payment.mode" true-label="type" false-label="type">付款分类报表</en-checkbox>
                </en-form-item>

                <en-form-item label="付款日期" v-if="payment.mode === 'daily'">
                  <en-date-picker
                    v-model:start="payment.form.data.paymentStartDate"
                    v-model:end="payment.form.data.paymentEndDate"
                    type="daterange"
                    value-format="YYYY-MM-DD"
                  ></en-date-picker>
                </en-form-item>

                <en-form-item label="付款日期" v-if="payment.mode === 'monthly'">
                  <en-date-picker
                    v-model:start="payment.form.data.paymentStartDate"
                    v-model:end="payment.form.data.paymentEndDate"
                    type="monthrange"
                    value-format="YYYY-MM-DD"
                  ></en-date-picker>
                </en-form-item>

                <en-form-item label="付款日期" v-if="payment.mode === 'detail'">
                  <en-date-picker
                    v-model:start="payment.form.data.payment.startDate"
                    v-model:end="payment.form.data.payment.endDate"
                    type="daterange"
                    value-format="YYYY-MM-DD"
                  ></en-date-picker>
                </en-form-item>

                <en-form-item label="付款日期" v-if="payment.mode === 'type'">
                  <en-date-picker
                    v-model:start="payment.form.data.payment.startDate"
                    v-model:end="payment.form.data.payment.endDate"
                    type="daterange"
                    value-format="YYYY-MM-DD"
                  ></en-date-picker>
                </en-form-item>
              </form-query>

              <flex-box>
                <template #default="{ height }">
                  <table-dynamic
                    v-if="payment.mode === 'daily'"
                    code="PMTDYSTMFLD"
                    :height="height"
                    :data="payment.table.data"
                    :loading="payment.table.loading"
                    :paging="payment.table.paging"
                    :summary-method="payment.table.summaryMethod"
                    :method="payment.table.daily"
                    pagination
                    show-summary
                    :config="payment.config"
                  >
                    <template #INDEX="{ $index }">
                      {{ $index + 1 }}
                    </template>
                  </table-dynamic>

                  <table-dynamic
                    v-if="payment.mode === 'monthly'"
                    code="PMTMYSTMFLD"
                    :height="height"
                    :data="payment.table.data"
                    :loading="payment.table.loading"
                    :paging="payment.table.paging"
                    :summary="payment.table.summary.data"
                    :method="payment.table.monthly"
                    pagination
                    show-summary
                    :config="payment.config"
                  >
                    <template #INDEX="{ $index }">
                      {{ $index + 1 }}
                    </template>
                  </table-dynamic>

                  <table-dynamic
                    v-if="payment.mode === 'detail'"
                    code="PMTDSTM"
                    :height="height"
                    :data="payment.table.data"
                    :loading="payment.table.loading"
                    :paging="payment.table.paging"
                    :summary="payment.table.summary.data"
                    :method="payment.table.detail"
                    pagination
                    show-summary
                    :config="payment.config"
                  >
                    <template #INDEX="{ $index }">
                      {{ $index + 1 }}
                    </template>
                  </table-dynamic>

                  <table-dynamic
                    v-if="payment.mode === 'type'"
                    code="PMTMTHSTM"
                    :height="height"
                    :data="payment.table.data"
                    :loading="payment.table.loading"
                    :paging="payment.table.paging"
                    :summary="payment.table.summary.data"
                    :method="payment.table.type"
                    pagination
                    show-summary
                    :config="payment.config"
                  >
                    <template #INDEX="{ $index }">
                      {{ $index + 1 }}
                    </template>
                  </table-dynamic>
                </template>
              </flex-box>
            </en-tab-pane>
          </en-tabs>
        </template>
      </flex-box>
    </en-card>

    <en-drawer v-model="detail.visible" title="查看总计" size="20%">
      <div style="{{ height: '10px' }}"></div>
      <div v-for="item in detail.table.data" class="flex flex-col gap-7">
        <div v-if="item.paymentMethodSummaries">
          <div v-for="itemDesc in item.paymentMethodSummaries">
            <div class="flex justify-between">
              <div style="color: #4c58d9ff">{{ itemDesc.paymentMethod }}收款{{ formatMoney(itemDesc.amount) }}</div>
              <div style="color: #4c58d9ff">减免{{ formatMoney(itemDesc.badDebt) }}</div>
            </div>
          </div>
        </div>
        <div style="{{ height: '10px' }}"></div>
        <div class="flex justify-between">
          <span style="color: #4c58d9ff">合计收款 ¥{{ item.amount }}</span>
          <span style="color: #4c58d9ff">合计减免 ¥{{ item.badDebt }}</span>
        </div>
        <div class="flex justify-between">
          <span style="color: #4c58d9ff">收据单数 {{ item?.count ?? 0 }}</span>
          <span style="color: #4c58d9ff">应收款 ¥{{ item.totalAmount }}</span>
        </div>
      </div>

      <template #footer>
        <en-button type="primary" @click="detail.footer.cancel.click">取消</en-button>
        <en-button type="primary" @click="detail.footer.confirm.click">确定</en-button>
      </template>
    </en-drawer>

    <en-drawer v-model="paymentDetail.visible" title="查看总计" size="20%">
      <div style="{{ height: '10px' }}"></div>
      <div v-for="item in paymentDetail.table.data" class="flex flex-col gap-7">
        <div v-if="item.paymentMethodSummaries">
          <div v-for="itemDesc in item.paymentMethodSummaries">
            <div class="flex justify-between">
              <div style="color: #4c58d9ff">{{ itemDesc.paymentMethod }}付款{{ formatMoney(itemDesc.amount) }}</div>
              <div style="color: #4c58d9ff">减免{{ formatMoney(itemDesc.badDebt) }}</div>
            </div>
          </div>
        </div>
        <div style="{{ height: '10px' }}"></div>
        <div class="flex justify-between">
          <span style="color: #4c58d9ff">合计付款 ¥{{ item.amount }}</span>
          <span style="color: #4c58d9ff">合计减免 ¥{{ item.badDebt }}</span>
        </div>
        <div class="flex justify-between">
          <span style="color: #4c58d9ff">付款单数 {{ item?.count ?? 0 }}</span>
          <span style="color: #4c58d9ff">应付款 ¥{{ item.totalAmount }}</span>
        </div>
      </div>

      <template #footer>
        <en-button type="primary" @click="paymentDetail.footer.cancel.click">取消</en-button>
        <en-button type="primary" @click="paymentDetail.footer.confirm.click">确定</en-button>
      </template>
    </en-drawer>
  </view-item>
</template>

<script lang="ts">
import dayjs from 'dayjs'
import { omit, pick } from 'lodash-es'

// const
export default factory({
  config: {
    children: {
      operation: {
        children: {
          total: {
            async click() {
              switch (this.tabs.active) {
                case 'customer':
                  this.detail.form.data.mode = this.customer.mode
                  this.detail.form.data.receiptStartDate = ['daily', 'monthly'].includes(this.customer.mode)
                    ? this.customer.form.data.receiptStartDate
                    : this.customer.form.data.receipt.businessStartDate
                  this.detail.form.data.receiptEndDate = ['daily', 'monthly'].includes(this.customer.mode)
                    ? this.customer.form.data.receiptEndDate
                    : this.customer.form.data.receipt.businessEndDate
                  await this.detail.table.daily()
                  this.detail.visible = true
                  break
                case 'payment':
                  this.paymentDetail.form.data.mode = this.payment.mode
                  this.paymentDetail.form.data.paymentStartDate = ['daily', 'monthly'].includes(this.payment.mode)
                    ? this.payment.form.data.paymentStartDate
                    : this.payment.form.data.payment.startDate
                  this.paymentDetail.form.data.paymentEndDate = ['daily', 'monthly'].includes(this.payment.mode)
                    ? this.payment.form.data.paymentEndDate
                    : this.payment.form.data.payment.startDate
                  await this.paymentDetail.table.daily()
                  this.paymentDetail.visible = true
                  break
              }
            }
          },
          export: {
            ajax: {
              customer: {
                action: 'GET /enocloud/statement/:timeWindow/receipt/export',
                params(params) {
                  params.paths = ['daily']
                  params.payload = omit(this.customer.form.data, 'mode')
                }
              },
              payment: {
                action: 'GET /enocloud/statement/:timeWindow/payment/export',
                params(params) {
                  params.paths = ['daily']
                  params.payload = omit(this.payment.form.data, 'mode')
                }
              }
            },
            click() {
              switch (this.tabs.active) {
                case 'customer':
                  if (this.customer.form.data.mode === 'daily') this.operation.export.customer()
                  this.store.openDownload()
                  break
                case 'payment':
                  if (this.payment.form.data.mode === 'daily') this.operation.export.payment()
                  this.store.openDownload()
                  break
              }
            }
          }
        }
      },
      detail: {
        mode: 'daily',
        visible: false,
        children: {
          form: {
            is: 'form',
            data: {
              receiptEndDate: '',
              receiptStartDate: '',
              receipt: {
                businessStartDate: '',
                businessEndDate: ''
              }
            }
          },
          table: {
            ajax: {
              daily: {
                action: 'GET /enocloud/statement/:timeWindow/receipt/summary',
                data: 'array',
                loading: true,
                pagination: true,
                params(params) {
                  params.paths = ['daily']
                  params.payload = pick(this.detail.form.data, 'receiptStartDate', 'receiptEndDate', 'mode')
                }
              }
            }
          }
        },
        footer: {
          cancel: {
            click() {
              this.detail.visible = false
            }
          },
          confirm: {
            click() {
              this.detail.visible = false
            }
          }
        }
      },
      paymentDetail: {
        mode: 'daily',
        visible: false,
        children: {
          form: {
            is: 'form',
            data: {
              paymentEndDate: '',
              paymentStartDate: ''
            }
          },
          table: {
            ajax: {
              daily: {
                action: 'GET /enocloud/statement/:timeWindow/payment/summary',
                data: 'array',
                loading: true,
                pagination: true,
                params(params) {
                  params.paths = ['daily']
                  params.payload = pick(this.paymentDetail.form.data, 'paymentStartDate', 'paymentEndDate', 'mode')
                }
              }
            }
          }
        },
        footer: {
          cancel: {
            click() {
              this.paymentDetail.visible = false
            }
          },
          confirm: {
            click() {
              this.paymentDetail.visible = false
            }
          }
        }
      },
      tabs: {
        active: 'customer'
      },
      payment: {
        mode: 'daily' as 'daily' | 'monthly' | 'detail' | 'type',
        children: {
          form: {
            data: {
              paymentStartDate: dayjs().startOf('month').format('YYYY-MM-DD'),
              paymentEndDate: dayjs().endOf('month').format('YYYY-MM-DD'),
              payment: {
                endDate: dayjs().format('YYYY-MM-DD'),
                startDate: dayjs().startOf('month').format('YYYY-MM-DD')
              }
            }
          },
          table: {
            ajax: {
              daily: {
                action: 'GET /enocloud/statement/:timeWindow/payment',
                summary: 'GET /enocloud/statement/:timeWindow/payment/summary',
                data: 'array',
                loading: true,
                pagination: true,
                params(params) {
                  params.paths = ['daily']
                  params.payload = pick(this.payment.form.data, 'paymentStartDate', 'paymentEndDate', 'mode')
                }
              },
              monthly: {
                action: 'GET /enocloud/statement/:timeWindow/payment',
                summary: 'GET /enocloud/statement/:timeWindow/payment/summary',
                data: 'array',
                loading: true,
                pagination: true,
                params(params) {
                  params.paths = ['monthly']
                  params.payload = pick(this.payment.form.data, 'paymentStartDate', 'paymentEndDate', 'mode')

                  // 统计时候结束日期是当月最后一天
                  params.payload.paymentEndDate = params.payload.paymentEndDate
                    ? dayjs(params.payload.paymentEndDate).endOf('M').format('YYYY-MM-DD')
                    : ''
                }
              },
              detail: {
                action: 'GET /enocloud/statement/payment/detail',
                summary: 'GET /enocloud/statement/payment/method/summary',
                data: 'array',
                loading: true,
                pagination: true,
                params(params) {
                  params.payload = pick(this.payment.form.data, 'payment.endDate', 'payment.startDate')
                }
              },
              type: {
                action: 'GET /enocloud/statement/payment/method',
                summary: 'GET /enocloud/statement/payment/method/summary',
                data: 'array',
                loading: true,
                pagination: true,
                params(params) {
                  params.payload = pick(this.payment.form.data, 'payment.endDate', 'payment.startDate')
                }
              }
            }
          },
          config: {
            SERIAL_NO: {
              header: {
                filter: {
                  type: 'text',
                  field: 'payment.serialNo'
                }
              }
            },
            INVOICE_NO: {
              header: {
                filter: {
                  type: 'text',
                  field: 'payment.invoiceNo'
                }
              }
            },
            COMMENT: {
              header: {
                filter: {
                  type: 'text',
                  field: 'payment.comment'
                }
              }
            },
            CUSTOMER_NAME: {
              header: {
                filter: {
                  type: 'select',
                  field: 'payeeNames',
                  props: {
                    allowCreate: true,
                    ajax: {
                      action: 'GET /enocloud/common/customer',
                      params: (params, value) => (params.payload = { name: value })
                    },
                    props: { label: 'name', value: '' },
                    remote: true,
                    multiple: true
                  }
                }
              }
            },
            DOCUMENT_SERIAL_NO: {
              header: {
                filter: {
                  type: 'text',
                  field: 'payment.documentSerialNo'
                }
              }
            },
            INVOICE_TYPE: { header: { filter: { type: 'text', field: 'payment.invoiceTypeName' } } },
            PAYMENT_METHOD: {
              header: {
                filter: {
                  type: 'select',
                  field: 'paymentMethods',
                  props: {
                    ajax: {
                      action: 'GET /enocloud/common/hint/:hintTypeStr',
                      params: (params: any) => (params.paths = ['PAMTMTD'])
                    },
                    props: { label: 'message', value: 'id' }
                  }
                }
              }
            }
          }
        }
      },
      customer: {
        mode: 'daily' as 'daily' | 'monthly' | 'detail' | 'type',
        children: {
          config: {
            SERIAL_NO: {
              header: {
                filter: {
                  type: 'text',
                  field: 'receipt.serialNo'
                }
              }
            },
            RECEIVABLE_INVOICE_NO: {
              header: {
                filter: {
                  type: 'text',
                  field: 'receipt.receivableInvoiceNo'
                }
              }
            },
            RECEIVABLE_TYPE: {
              header: {
                filter: {
                  type: 'select',
                  field: 'invoiceTypeName',
                  props: {
                    ajax: {
                      action: 'GET /enocloud/common/lookup/:lookupType',
                      params: (params) => {
                        params.paths = ['RVBTYPE']
                      }
                    },
                    allowCreate: true,
                    remote: true,
                    multiple: true,
                    props: { label: 'message', value: 'code' }
                  }
                }
              }
            },
            PLATE_NO: {
              header: {
                filter: {
                  type: 'text',
                  field: 'receipt.plateNo'
                }
              }
            },
            SETTLED_DATETIME: {
              header: {
                filter: {
                  type: 'date',
                  field: ['startDate', 'endDate'],
                  props: { type: 'daterange' }
                }
              }
            },
            CUSTOMER_NAME: {
              header: {
                filter: {
                  type: 'select',
                  field: 'receipt.customerNames',
                  props: {
                    ajax: {
                      action: 'GET /enocloud/common/customer',
                      params: (params, value) => (params.payload = { name: value })
                    },
                    remote: true,
                    allowCreate: true,
                    props: { label: 'name', value: 'name' }
                  }
                }
              }
            },
            COMMENT: {
              header: {
                filter: {
                  type: 'text',
                  field: 'receipt.comment'
                }
              }
            },
            RECEIVABLE_SERIAL_NO: {
              header: {
                filter: {
                  type: 'text',
                  field: 'documentSerialNo'
                }
              }
            },
            PAYMENT_METHOD: {
              header: {
                filter: {
                  type: 'select',
                  field: 'paymentMethods',
                  props: {
                    ajax: {
                      action: 'GET /enocloud/common/hint/:hintTypeStr',
                      params: (params: any) => (params.paths = ['PAMTMTD'])
                    },
                    props: { label: 'name', value: 'name' }
                  }
                }
              }
            }
          },
          form: {
            data: {
              branchIds: [],
              receiptStartDate: dayjs().startOf('month').format('YYYY-MM-DD'),
              receiptEndDate: dayjs().format('YYYY-MM-DD'),
              receipt: {
                businessStartDate: '',
                businessEndDate: ''
              }
            }
          },
          table: {
            config: {
              SERIAL_NO: { header: { filter: { type: 'text', field: 'serialNo' } } }
            },
            ajax: {
              daily: {
                action: 'GET /enocloud/statement/:timeWindow/receipt',
                summary: 'GET /enocloud/statement/:timeWindow/receipt/summary',
                data: 'array',
                pagination: true,
                loading: true,
                params(params) {
                  params.paths = ['daily']
                  params.payload = pick(this.customer.form.data, 'mode', 'receiptStartDate', 'receiptEndDate', 'branchIds')
                }
              },
              monthly: {
                action: 'GET /enocloud/statement/:timeWindow/receipt',
                summary: 'GET /enocloud/statement/:timeWindow/receipt/summary',
                data: 'array',
                loading: true,
                pagination: true,
                params(params) {
                  params.paths = ['monthly']
                  params.payload = pick(this.customer.form.data, 'mode', 'receiptStartDate', 'receiptEndDate', 'branchIdss')
                }
              },
              detail: {
                action: 'GET /enocloud/statement/receipt/detail',
                summary: 'GET /enocloud/statement/receipt/detail/summary',
                data: 'array',
                loading: true,
                pagination: true,
                params(params) {
                  params.payload = pick(this.customer.form.data, 'receipt.businessStartDate', 'receipt.businessEndDate', 'mode', 'branchIds')
                }
              },
              type: {
                action: 'GET /enocloud/statement/receipt/payment/method',
                summary: 'GET /enocloud/statement/receipt/payment/method/summary',
                data: 'array',
                loading: true,
                pagination: true,
                params(params) {
                  params.payload = pick(this.customer.form.data, 'receipt.businessStartDate', 'receipt.businessEndDate', 'mode', 'branchIds')
                }
              }
            }
          }
        }
      }
    }
  },

  mounted() {
    this.customer.table.daily()

    window.xx = dayjs
  }
})
</script>
